<template>
    <BlockUI :blocked="loadingCard">
        <div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <div>
                <Dropdown v-model="filters.tipe" :options="dataDropdownTipe" optionLabel="name" optionValue="code" placeholder="Pilih Tipe" />    
            </div>
            <Badge value="Export Excel" size="large" severity="success" @click="exportExcelCSV('xlsx')" class="detail_active" ></Badge>
        </div>
        <Chart type="bar" :data="dataChart" :options="options"/>
    </BlockUI>
</template>

<script>

export default {
    props: [
		"filter",
		"filter2",
		"filter3",
		// "startDate",
		// "endDate",
    ],
    data() {
        return {
            // loading
            loading: false,
            loadingCard: false,
            loadingExcel: false,
            loadingCsv: false,

            // dataDropdown
            dataDropdownTipe: [
                {name: 'Regular', code: 'regular'},
                {name: 'NPL', code: 'npl'},
            ],

            // filter
            filters: {
                tipe: 'regular',
            },

            percentage: null,
            dataChart: null,
            options: {
                indexAxis: 'y',
                elements: {
                    bar: {
                        borderWidth: 2,
                    }
                },
                responsive: true,
                plugins: {
                    legend: {
                        position: 'bottom',
                        display: false,
                    },
                    // tooltip: {
                    //     enabled: false,
                    //     external: function(context) {
                    //         // Tooltip Element
                    //         let tooltipEl = document.getElementById('chartjs-tooltip');

                    //         // Create element on first render
                    //         if (!tooltipEl) {
                    //         tooltipEl = document.createElement('div');
                    //         tooltipEl.id = 'chartjs-tooltip';
                    //         tooltipEl.className = 'external-tooltip';
                    //         tooltipEl.innerHTML = '<table></table>';
                    //         document.body.appendChild(tooltipEl);
                    //         }

                    //         // Hide if no tooltip
                    //         const tooltipModel = context.tooltip;
                    //         // console.log(tooltipModel);
                    //         if (tooltipModel.opacity === 0) {
                    //         tooltipEl.style.opacity = 0;
                    //         return;
                    //         }

                    //         // Set caret Position
                    //         tooltipEl.classList.remove('above', 'below', 'no-transform');
                    //         if (tooltipModel.yAlign) {
                    //         tooltipEl.classList.add(tooltipModel.yAlign);
                    //         } else {
                    //         tooltipEl.classList.add('no-transform');
                    //         }

                    //         function getBody(bodyItem) {
                    //         return bodyItem.lines;
                    //         }

                    //         // Set Text
                    //         if (tooltipModel.body) {
                    //         const titleLines = tooltipModel.title || [];
                    //         const bodyLines = tooltipModel.body.map(getBody);

                    //         let innerHtml = '';

                    //         if (titleLines.length > 0) {
                    //             innerHtml += '<div class="tooltip-title">' + titleLines[0] + '</div>';
                    //         }

                    //         innerHtml += '<div class="tooltip-body"><table>';

                    //         bodyLines.forEach(function(body, i) {
                    //             const colors = tooltipModel.labelColors[i];
                    //             const index = tooltipModel.dataPoints[0].dataIndex;
                    //             const label = tooltipModel.dataPoints[0].dataset.tooltipData[index];
                                
                    //             let style = 'background:' + colors.backgroundColor;
                    //             style += '; border-color:' + colors.borderColor;
                    //             style += '; border-width: 2px';
                    //             const span = '<span style="' + style + '"></span>';
                    //             innerHtml += '<tr><td>' + span + body + '</td></tr>';
                    //             innerHtml += '<tr><td>' + span + label + '</td></tr>';
                    //         });

                    //         innerHtml += '</table></div>';

                    //         tooltipEl.innerHTML = innerHtml;
                    //         }

                    //         const position = context.chart.canvas.getBoundingClientRect();
                    //         // Display, position, and set styles for font
                    //         tooltipEl.style.opacity = 1;
                    //         tooltipEl.style.position = 'absolute';
                    //         tooltipEl.style.left = position.left + window.pageXOffset + tooltipModel.caretX + 'px';
                    //         tooltipEl.style.top = position.top + window.pageYOffset + tooltipModel.caretY + 'px';
                    //         tooltipEl.style.padding = tooltipModel.padding + 'px ' + tooltipModel.padding + 'px';
                    //         tooltipEl.style.pointerEvents = 'none';
                    //     }
                    // }
                }
            },
        }
    },
	mounted() {
        this.getData();
	},
    computed:{
    },
    watch: {
        filters: {
            handler() {
                setTimeout(() => {
                    this.getData()
                }, 500);
            },
            deep: true
        },
        filter: {
            handler() {
                setTimeout(() => {
                    this.getData();
                }, 500);
            },
            deep: true
        },
        filter2: {
            handler() {
                setTimeout(() => {
                    this.getData();
                }, 500);
            },
            deep: true
        },
        filter3: {
            handler() {
                setTimeout(() => {
                    this.getData();
                }, 500);
            },
            deep: true
        },
        // endDate: {
        //     handler() {
        //         setTimeout(() => {
        //             if(
        //                 this.endDate !== null && 
        //                 this.endDate !== 'Invalid date'
        //             ){  
        //                 this.getData();
        //             }
        //         }, 500);
        //     },
        // }
    },
	methods: {
        getData(){

            this.loadingCard= true;

            this.axios({
                method: 'GET',
                url: process.env.VUE_APP_ROOT_API + 'web/dashboard/top-products-chart',
                params: {
                    "userweb_id": this.filter.userweb_id,
                    "periode": this.filter2.period,
                    "otype_id": this.filter3.otype_id,
                    "type": this.filters.tipe,
                    // "tanggal_mulai": this.startDate,
                    // "tanggal_akhir": this.endDate,
                }
            })
            .then(res => {
                this.dataChart = res.data.data;
                this.percentage =  res.data.data.datasets[0].persen;
                this.loadingCard= false;
                
            })
            .catch((err) => {
                this.loadingCard= false;
                console.log(err);
            });
        },
        // EXPORT
        exportExcelCSV(ext){
            if(ext == 'xlsx'){
                this.loadingExcel=true;
            }else if(ext == 'csv'){
                this.loadingCsv=true;
            }

            this.axios({
                method: 'GET',
                url: process.env.VUE_APP_ROOT_API + 'web/dashboard/top-products-chart/export',
                responseType: 'blob',
                params: {
                    "ext" : ext,
                    "userweb_id": this.filter.userweb_id,
                    "periode": this.filter2.period,
                    "otype_id": this.filter3.otype_id,
                    "type": this.filters.tipe,
                }
            })
            .then(response => {
                let fileUrl = window.URL.createObjectURL(response.data);
                let fileLink = document.createElement('a');

                fileLink.href = fileUrl;

                fileLink.setAttribute('download', 'Top 10 Products Export.' + ext);
                
                document.body.appendChild(fileLink)

                fileLink.click();
                
                if(ext == 'xlsx'){
                    this.loadingExcel=false;
                }else if(ext == 'csv'){
                    this.loadingCsv=false;
                }

            })
            .catch((err) => {
                console.log(err);
                
                if(ext == 'xlsx'){
                    this.loadingExcel=false;
                }else if(ext == 'csv'){
                    this.loadingCsv=false;
                }
            });
        },
	},
}
</script>

<style>
#chartjs-tooltip{
    background: #ddd;
    padding: 8px
}
.external-tooltip {
  position: absolute;
  font-family: Arial, sans-serif;
  font-size: 12px;
  z-index: 9999;
  pointer-events: none;
  opacity: 0;
  transition: opacity 0.3s;
}

.external-tooltip table {
  width: 100%;
  margin: 0;
  padding: 0;
  border-collapse: collapse;
}

.external-tooltip th,
.external-tooltip td {
  padding: 6px 8px;
  text-align: left;
}

.external-tooltip th {
  background-color: #333;
  color: #fff;
  font-weight: bold;
}

.external-tooltip td {
  background-color: #fff;
  border-top: 1px solid #ddd;
}

.external-tooltip td span {
  display: inline-block;
  width: 12px;
  height: 12px;
  margin-right: 6px;
  vertical-align: middle;
}

.external-tooltip .tooltip-title {
  font-weight: bold;
  margin-bottom: 6px;
}

.external-tooltip .tooltip-body {
  margin-top: 6px;
}


.detail_active{
    transition: all 0.3s; /* untuk memberikan efek transisi saat hover */
}
.detail_active:hover {
    box-shadow: 4px 4px 12px rgba(0, 0, 0, 0.2); /* meningkatkan efek shadow */
    transform: scale(1.01); /* mengubah skala card */
    cursor: pointer; /* mengubah cursor menjadi bentuk pointer saat hover */
}
</style>