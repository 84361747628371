<template>
    <BlockUI :blocked="loadingCard">
        <Badge :value="percentage" class="mr-2" size="large" severity="primary"></Badge>
        <small  @click="detailItem()" class="detail_active"><i><u>detail</u></i></small>
        <Chart type="bar" :data="dataChart" :options="options"/>
    </BlockUI>

    <Dialog v-model:visible="detailItemDialog" :breakpoints="{ '960px': '75vw', '640px': '100vw' }" :style="{ width: '50vw' }" header="Data Detil - Visit Mitra" :modal="true" :maximizable="true">
        <DataTable :value="dataTable" responsiveLayout="scroll" :loading="loading" dataKey="number" @sort="onSort($event)" :resizableColumns="true" columnResizeMode="fit">
            <template #header>
                <div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
                    <div>
                        <Button :loading="loadingExcel" label="Export Excel" icon="pi pi-file-excel" class="p-button p-button-success mr-2 my-1 inline-block" @click="exportExcelCSV('xlsx')" />
                        <Button :loading="loadingCsv" label="Export CSV" icon="pi pi-file-excel" class="p-button p-button-success mr-2 my-1 inline-block" @click="exportExcelCSV('csv')" />
                    </div>
                    <span class="block mt-2 md:mt-0 p-input-icon-left">
                        <i class="pi pi-search" />
                        <InputText v-model="search" placeholder="Cari..." @keyup.enter="getDataTable" />
                    </span>
                </div>
            </template>
            <Column field="number" header="No.">
                <template #body="slotProps">
                {{ slotProps.data.number }}
                </template>
            </Column>
            <Column field="periode" header="Periode">
                <template #body="slotProps">
                {{ slotProps.data.periode }}
                </template>
            </Column>
            <Column field="area_name" header="Area">
                <template #body="slotProps">
                {{ slotProps.data.area_name }}
                </template>
            </Column>
            <Column field="region_name" header="Region">
                <template #body="slotProps">
                {{ slotProps.data.region_name }}
                </template>
            </Column>
            <Column field="branch_name" header="Branch">
                <template #body="slotProps">
                {{ slotProps.data.branch_name }}
                </template>
            </Column>
            <Column field="subbranch_name" header="Sub Branch">
                <template #body="slotProps">
                {{ slotProps.data.subbranch_name }}
                </template>
            </Column>
            <Column field="username" header="Username">
                <template #body="slotProps">
                {{ slotProps.data.username }}
                </template>
            </Column>
            <Column field="total_target" header="Target">
                <template #body="slotProps">
                {{ slotProps.data.total_target }}
                </template>
            </Column>
            <Column field="total_actual" header="Actual">
                <template #body="slotProps">
                {{ slotProps.data.total_actual }}
                </template>
            </Column>
            <Column field="pct" header="Percentage">
                <template #body="slotProps">
                {{ slotProps.data.pct }}
                </template>
            </Column>
            <template #empty>
                No data found.
            </template>
            <template #loading>
                Loading data. Please wait.
            </template>
            <template #footer>
                In total there are {{totalItemsCount ? totalItemsCount : 0 }} data.
            </template>
        </DataTable>

        <Paginator v-model:rows="rows" v-model:first="offset" :totalRecords="totalItemsCount" :rowsPerPageOptions="[10,20,30]">
            <template>
                {{totalItemsCount}}
            </template>
        </Paginator>

        <template #footer>
            <Button label="Batal" icon="pi pi-times" class="p-button-text" @click="hideDialog"/>
        </template>

    </Dialog>
</template>

<script>

import { numberingDatatable } from "../../../utils/helpers";

export default {
    props: [
		"filter",
		"filter2",
		// "startDate",
		// "endDate",
    ],
    data() {
        return {
            // loading
            loading: false,
            loadingCard: false,
            loadingExcel: false,
            loadingCsv: false,

            percentage: null,
            dataChart: null,
            options: {
                indexAxis: 'y',
                elements: {
                    bar: {
                        borderWidth: 2,
                    }
                },
                responsive: true,
                plugins: {
                    legend: {
                        position: 'bottom',
                        display: false,
                    },
                    // tooltip: {
                    //     enabled: false,
                    //     external: function(context) {
                    //         // Tooltip Element
                    //         let tooltipEl = document.getElementById('chartjs-tooltip');

                    //         // Create element on first render
                    //         if (!tooltipEl) {
                    //         tooltipEl = document.createElement('div');
                    //         tooltipEl.id = 'chartjs-tooltip';
                    //         tooltipEl.className = 'external-tooltip';
                    //         tooltipEl.innerHTML = '<table></table>';
                    //         document.body.appendChild(tooltipEl);
                    //         }

                    //         // Hide if no tooltip
                    //         const tooltipModel = context.tooltip;
                    //         // console.log(tooltipModel);
                    //         if (tooltipModel.opacity === 0) {
                    //         tooltipEl.style.opacity = 0;
                    //         return;
                    //         }

                    //         // Set caret Position
                    //         tooltipEl.classList.remove('above', 'below', 'no-transform');
                    //         if (tooltipModel.yAlign) {
                    //         tooltipEl.classList.add(tooltipModel.yAlign);
                    //         } else {
                    //         tooltipEl.classList.add('no-transform');
                    //         }

                    //         function getBody(bodyItem) {
                    //         return bodyItem.lines;
                    //         }

                    //         // Set Text
                    //         if (tooltipModel.body) {
                    //         const titleLines = tooltipModel.title || [];
                    //         const bodyLines = tooltipModel.body.map(getBody);

                    //         let innerHtml = '';

                    //         if (titleLines.length > 0) {
                    //             innerHtml += '<div class="tooltip-title">' + titleLines[0] + '</div>';
                    //         }

                    //         innerHtml += '<div class="tooltip-body"><table>';

                    //         bodyLines.forEach(function(body, i) {
                    //             const colors = tooltipModel.labelColors[i];
                    //             const index = tooltipModel.dataPoints[0].dataIndex;
                    //             const label = tooltipModel.dataPoints[0].dataset.tooltipData[index];
                                
                    //             let style = 'background:' + colors.backgroundColor;
                    //             style += '; border-color:' + colors.borderColor;
                    //             style += '; border-width: 2px';
                    //             const span = '<span style="' + style + '"></span>';
                    //             innerHtml += '<tr><td>' + span + body + '</td></tr>';
                    //             innerHtml += '<tr><td>' + span + label + '</td></tr>';
                    //         });

                    //         innerHtml += '</table></div>';

                    //         tooltipEl.innerHTML = innerHtml;
                    //         }

                    //         const position = context.chart.canvas.getBoundingClientRect();
                    //         // Display, position, and set styles for font
                    //         tooltipEl.style.opacity = 1;
                    //         tooltipEl.style.position = 'absolute';
                    //         tooltipEl.style.left = position.left + window.pageXOffset + tooltipModel.caretX + 'px';
                    //         tooltipEl.style.top = position.top + window.pageYOffset + tooltipModel.caretY + 'px';
                    //         tooltipEl.style.padding = tooltipModel.padding + 'px ' + tooltipModel.padding + 'px';
                    //         tooltipEl.style.pointerEvents = 'none';
                    //     }
                    // }
                }
            },
            
            //detail
            detailItemDialog: false,

            //datatables
            data: null,
            dataTable: null,
            rows: 10,
            search: null,
            offset: null,
            field: null,
            sort: null,
            page: null,
            totalItemsCount: 0,
        }
    },
	mounted() {
        this.getData();
	},
    computed:{
    },
    watch: {
        offset() {
            this.getDataTable();
        },
        rows() {
            this.getDataTable();
        },
        filter: {
            handler() {
                setTimeout(() => {
                    this.getData();
                }, 500);
            },
            deep: true
        },
        filter2: {
            handler() {
                setTimeout(() => {
                    this.getData();
                }, 500);
            },
            deep: true
        },
        // endDate: {
        //     handler() {
        //         setTimeout(() => {
        //             if(
        //                 this.endDate !== null && 
        //                 this.endDate !== 'Invalid date'
        //             ){  
        //                 this.getData();
        //             }
        //         }, 500);
        //     },
        // }
    },
	methods: {
        getData(){

            this.loadingCard= true;

            this.axios({
                method: 'GET',
                url: process.env.VUE_APP_ROOT_API + 'web/dashboard/visit-mitra-chart',
                params: {
                    "userweb_id": this.filter.userweb_id,
                    "periode": this.filter2.period,
                    // "tanggal_mulai": this.startDate,
                    // "tanggal_akhir": this.endDate,
                }
            })
            .then(res => {
                this.dataChart = res.data.data;
                this.percentage =  res.data.data.datasets[0].persen;
                this.loadingCard= false;
                
            })
            .catch((err) => {
                this.loadingCard= false;
                console.log(err);
            });
        },
        // DATATABLE
        detailItem() {
            this.clearDataTable();
            this.$store.commit('setErrors', {});

            this.getDataTable();
            this.detailItemDialog = true;
        },
        hideDialog() {
            this.detailItemDialog = false;
        },
        //
        getDataTable(){
          
            this.loading=true;
            this.page = (this.offset / this.rows) + 1;

            this.axios({
                method: 'GET',
                url: process.env.VUE_APP_ROOT_API + 'web/dashboard/visit-mitra-chart-detail',
                params: {
                    "search" : this.search,
                    "per_page" : this.rows,
                    "page" : this.page,
                    "order_by" : this.field,
                    "sort_by" : this.sort,
                    "userweb_id": this.filter.userweb_id,
                    "periode": this.filter2.period,
                }
            })
            .then(res => {
              this.data = res.data.data.data;
              this.dataTable = numberingDatatable(res.data.data.data, this.page, this.rows);
              this.totalItemsCount = res.data.data.total;
              this.rows = parseInt(res.data.data.per_page);
              this.loading=false;
            })
            .catch((err) => {
                console.log(err);
                this.loading=false;
            });
         
        },
        onSort(event) {
            this.field = event.sortField;
            this.sort = event.sortOrder == '1' ? 'ASC' : 'DESC';
            this.getDataTable();
        },
        clearDataTable(){
            this.data= null;
            this.dataTable= null;
            this.rows= 10;
            this.search= null;
            this.offset= null;
            this.field= null;
            this.sort= null;
            this.page= null;
            this.totalItemsCount= 0;
        },
        // EXPORT
        exportExcelCSV(ext){
            if(ext == 'xlsx'){
                this.loadingExcel=true;
            }else if(ext == 'csv'){
                this.loadingCsv=true;
            }

            this.axios({
                method: 'GET',
                url: process.env.VUE_APP_ROOT_API + 'web/dashboard/visit-mitra-chart-detail/export',
                responseType: 'blob',
                params: {
                    "ext" : ext,
                    "userweb_id": this.filter.userweb_id,
                    "periode": this.filter2.period,
                }
            })
            .then(response => {
                let fileUrl = window.URL.createObjectURL(response.data);
                let fileLink = document.createElement('a');

                fileLink.href = fileUrl;

                fileLink.setAttribute('download', 'Visit Export.' + ext);
                
                document.body.appendChild(fileLink)

                fileLink.click();
                
                if(ext == 'xlsx'){
                    this.loadingExcel=false;
                }else if(ext == 'csv'){
                    this.loadingCsv=false;
                }

            })
            .catch((err) => {
                console.log(err);
                
                if(ext == 'xlsx'){
                    this.loadingExcel=false;
                }else if(ext == 'csv'){
                    this.loadingCsv=false;
                }
            });
        },
	},
}
</script>

<style>
#chartjs-tooltip{
    background: #ddd;
    padding: 8px
}
.external-tooltip {
  position: absolute;
  font-family: Arial, sans-serif;
  font-size: 12px;
  z-index: 9999;
  pointer-events: none;
  opacity: 0;
  transition: opacity 0.3s;
}

.external-tooltip table {
  width: 100%;
  margin: 0;
  padding: 0;
  border-collapse: collapse;
}

.external-tooltip th,
.external-tooltip td {
  padding: 6px 8px;
  text-align: left;
}

.external-tooltip th {
  background-color: #333;
  color: #fff;
  font-weight: bold;
}

.external-tooltip td {
  background-color: #fff;
  border-top: 1px solid #ddd;
}

.external-tooltip td span {
  display: inline-block;
  width: 12px;
  height: 12px;
  margin-right: 6px;
  vertical-align: middle;
}

.external-tooltip .tooltip-title {
  font-weight: bold;
  margin-bottom: 6px;
}

.external-tooltip .tooltip-body {
  margin-top: 6px;
}


.detail_active{
    transition: all 0.3s; /* untuk memberikan efek transisi saat hover */
}
.detail_active:hover {
    box-shadow: 4px 4px 12px rgba(0, 0, 0, 0.2); /* meningkatkan efek shadow */
    transform: scale(1.01); /* mengubah skala card */
    cursor: pointer; /* mengubah cursor menjadi bentuk pointer saat hover */
}
</style>